@import 'themes.scss';
@import 'mixins.scss';

$card-radius: 0px;
$textSection-radius: 10px;
$textarea-radius: 0px;

/*-----------------------------  Body ----------------------------*/

body .patientcontact .admin {
    @include themify ($themes) {
        font-size: 16px;
    }
}

/*-----------------------------  Card ---------------------------*/
.card {
    @include themify($themes) {
        background-color: themed('admin-panel-body-bg-color');
    }
    -webkit-box-shadow: 0 2px 6px -6px #000;
    -moz-box-shadow: 0 2px 6px -6px #000;
         box-shadow: 0 2px 6px -6px #000;
    margin-bottom: 16px;
}

.card.default .card-header, .card.default .card-body, .card.default, .card.default .card-footer {
    @include themify($themes) {
        border: 1px solid;
        border-color: themed('admin-panel-body-bg-color');
        background-color: themed('admin-panel-body-bg-color');
    }
}

.card.disabled .card-header, .card.disabled .card-body {
    @include themify($themes) {
        background-color: themed("disabled-color");
        color: themed("disabled-color-supplement");
    }
}

.card .card-footer {
  margin: 20px -15px -15px;
}

.card.default .card-header, .card.default .card-footer{
    @include themify($themes) {
        background-color: themed('admin-panel-heading-bg-color');
        padding-bottom: 0px;
    }
}

.card .card-header h5{
    @include themify($themes) {
        color: themed('admin-panel-heading-text-color');
    }
}

.card.default .card-header h4{
    @include themify($themes) {
        color: themed('admin-panel-heading-text-color');
    }
}

.card.disabled .card-header h4{
    @include themify($themes) {
        color: themed('disabled-color-supplement');
    }
}

.card-header:first-child {
    border-radius: $card-radius $card-radius 0 0;
}

.card-body{
    border-radius: $card-radius;
}

.card-body label{
    font-weight: bold;
    margin-bottom: 0;
}

.card-body .blackText{
    color: #000;
}

.cardLabel{
    margin: 5px 15px 0px 15px;
}

.card-body h5{
 font-size: 18px;
}


.navMenu
{
    color:  #f3f3f3;
}
/*---------------------------  Textarea --------------------------*/
.card .card-body .textSection{
    @include themify($themes) {
        background-color: themed('light-color-supplement');
        border: 1px solid themed('primary-color');
    }
    border-radius: $textSection-radius;
    border-top-right-radius: 0;
    padding: 15px;
    margin: 0 15px;
}

.preWrapText{
    white-space: pre-line;
}

p.whiteText{
    color: white;
}

ul.pagination a.page-link{
    min-width: 38px;
}

/*----------------------------  Lists ---------------------------*/

.list {
    list-style: none;
  }

  .list .listItem::before {
    @include themify($themes) {
        color: themed('primary-color');
    }
    content: "\2022";
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }

  /*----------------------------  Fonts ---------------------------*/


.smallText{
    font-size: 12px;
  }

  /*----------------------------  Forms ---------------------------*/
  .well .input-one-line {
    @include themify($themes){
        background-color: themed('light-color');
        border-color: themed('secondary-color');
        margin-bottom: 0.5em;
    }
  }

  .well .small-textarea {
    @include themify($themes){
        width: 100%;
        background-color: themed('light-color');
        border-color: themed('secondary-color');
        margin-bottom: 0.5em;
    }
  }

  /*-----------------------------  Buttons ----------------------------*/

  .btn.btn-symptom-btn{
    @include themify($themes){
        margin-top: 8px;
        font-size: 16px;
        color: themed('primary-color');
    }
  }

  .btn.btn-success{
    @include themify($themes){
        margin-top: 8px;
        font-size: 16px;
    }
  }

  .btn.btn-video{
    @include themify($themes){
        margin-top: 8px;
        font-size: 16px;
    }
  }


  .btn.small-btn{
    @include themify($themes){
        border-radius: 50%;
        font-size: 16px;
        margin: 0 auto;
        padding: 0px;
        text-align: center;
        width: 24px;
        height: 24px;
        }
    }

    .btn.btn-modal{
    @include themify($themes){
        margin-top: 0px;
        font-size: 16px;
        }
    }


/*-----------------------------  DropDown Menu ----------------------------*/
  .dropdown-item:hover{
    @include themify($themes){
        background-color: themed('list-color-odd-row');
    }
  }

  .status-dropdown:hover{
    @include themify($themes){
        background-color: themed('light-color');
    }
  }

  .dropdown-toggle:after{
      display: none;
  }

  .chooseCareCenter{
    margin-top: 8px;
    text-align: left;
}

  /*----------------------------  Switch/toggle button ---------------------------*/

.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 25px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 17px;
    width: 17px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input + .slider {
    @include themify($themes){
        background-color: themed('primary-color');
        }
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(25px);
    -ms-transform: translateX(25px);
    transform: translateX(25px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  .toggleLabelLeft{
    padding-top: 2px;
    padding-right: 5px;
  }

  .toggleLabelRight{
    padding-top: 2px;
    padding-left: 5px;
  }

  .toggleCloser{
      margin-bottom: -15px;
  }
  
  /*----------------------------  Other ---------------------------*/
    .topMargin-xs{
        margin-top: 3px;
    }

    .filterIcon{
      cursor: pointer;
    }

    .rightMargin-md{
        margin-right: 10px;
    }

    .react-datepicker__input-container{
        cursor: pointer;
    }

.overflowText{
    max-width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.marginBottom{
    margin-bottom: 60px;
}

.paddingTop5{
    padding-top: 5px;
}

.paddingTop25{
    padding-top: 25px;
}

@media screen and (min-width: 991px) {
    .hiddenXl{
        display: none;
    }
}

.createIssueText{
    @media screen and (max-width: 1200px) {
        padding-top: 25px;
    }
    @media screen and (min-width: 991px) {
            padding-top: 83px;
    }
}


.validationText{
  @include themify($themes) {
    color: themed('primary-color');
  }
}

/*---------------------------- tooltip ---------------------------*/

.tippy-box[data-theme~='tippy'] {
    @include themify($themes) {
        background-color: themed('primary-color');
      }
      white-space: pre-wrap;
      max-width: 100px;
      overflow-wrap: break-word;
  }

.tippy-box[data-theme~='tippy'][data-placement^='left'] > .tippy-arrow::before {
    @include themify($themes) {
        border-left-color: themed('primary-color');
      }
  }


  /*---------------------------- react daypicker ---------------------------*/


.react-datepicker-wrapper .react-datepicker__input-container .react-datepicker__close-icon {
    right: -30px;
  }
