/* Collabodoc Heading */
@font-face {
    font-family: 'proximanova-bold, sans-serif';
    src: local('Proxima Nova Bold'), url('./fonts/Proxima_Nova/Proxima Nova Bold.otf') format('truetype');
    src: local('ProximaNova-Bold'), url('./fonts/Proxima_Nova/ProximaNova-Bold.ttf') format('truetype');
}

/* Collabodoc Paragraph */
@font-face {
    font-family: 'proximanova-regular, sans-serif';
    src: local('Proxima Nova Regular'), url('./fonts/Proxima_Nova/Proxima Nova Regular.otf') format('truetype');
    src: local('ProximaNova-Regular'), url('./fonts/Proxima_Nova/ProximaNova-Regular.ttf') format('truetype');
}

body {
    color: #000000;
    font-size: 16px;
    font-family: "proximanova-regular, sans-serif";
    font-weight: 400;
    background-color: #fff;
    padding-top: 80px;
    padding-bottom: 20px;
}
